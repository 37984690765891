@import "~@/styles/variables";

.content {
  background-color: $color-tint-neutral-secondary;
}

@media screen and (max-width: 1025px) {
  .content {
    margin-left: $spacing-inline-32;
    margin-right: $spacing-inline-32;
    width: auto;
  }
}

.picture404 {
  padding-top: $spacing-stack-32;
}

.divider {
  width: 100px;
  border: 1px solid $color-tint-brand-primary;
}

.links {
  margin-top: $spacing-stack-32;
  padding-bottom: $spacing-stack-64;
}
